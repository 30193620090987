// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import lottie from 'lottie-web';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  // Review page
  if ($('.review-page').length) {
    let form = $('.review-page-popup-form'),
      social = $('.review-page-popup-socials'),
      main = $('.review-page'),
      stars = main.find('.stars-wrapper'),
      close = $('.close-button');
    close.on('click', function () {
      $(this).closest('section').hide();
    });
    stars.on('click', function () {
      stars.removeClass('is-active');
      $(this).addClass('is-active').prevAll().addClass('is-active');
      if ($(this).data('number') > 3) {
        social.show();
      } else {
        form.show();
      }
    });
    stars.mouseover(function () {
      $(this).addClass('is-active').prevAll().addClass('is-active');
      $(this).nextAll().removeClass('is-active');
    });
  }
  $('#test-height', window.parent.document).height($('body').height() + 50);

  // Get the current URL path
  const currentPath = window.location.pathname;

  // Check if the form was submitted
  if (localStorage.getItem('formSubmitted')) {
    // Hide the banner if the form was submitted
    $('.red-banner').addClass('hidden');
  } else if (!sessionStorage.getItem('popupShown')) {
    // Open the popup if it hasn't been shown in the current session
    $.fancybox.open({
      src: '#red-popup',
      type: 'inline',
      autoFocus: false,
    });

    // Mark the popup as shown using sessionStorage
    sessionStorage.setItem('popupShown', true);
  } else if (currentPath !== '/thank-you-promo/') {
    // Show the banner if the popup was already shown and not on the thank-you page
    $('.red-banner').removeClass('hidden');
  }

  // Track form submission event for Gravity Forms
  $(document).on('submit', '.gform_wrapper form', function () {
    // Save the flag in localStorage
    localStorage.setItem('formSubmitted', true);
  });

  // Track the popup close event
  $(document).on('afterClose.fb', function () {
    // Check for errors in the form
    if ($('.gform_validation_error').length > 0) {
      localStorage.removeItem('formSubmitted');
    }

    // Show the banner if the form was not submitted and not on the thank-you page
    if (
      !localStorage.getItem('formSubmitted') &&
      currentPath !== '/thank-you-promo/'
    ) {
      $('.red-banner').removeClass('hidden');
    }
  });

  /*
   *  This function will render each map when the document is ready (page has loaded)
   */
  /* global google */

  /*
   *  This function will render a Google Map onto the selected jQuery element
   */
  function render_map($el) {
    // var
    var $markers = $el.find('.marker');
    // var styles = [];
    // vars;
    var args = {
      zoom: 18,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
      scrollwheel: false,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: true,
      //styles: styles,
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // center map
    center_map(map);
  }

  /*
   *  Get current location on click
   */
  var infowindow;

  function add_marker($marker, map) {
    var latlng = new google.maps.LatLng(
      $marker.attr('data-lat'),
      $marker.attr('data-lng')
    );
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      icon: $marker.data('marker-icon'),
    });
    map.markers.push(marker);
    if ($.trim($marker.html())) {
      infowindow = new google.maps.InfoWindow();
      google.maps.event.addListener(marker, 'click', function () {
        infowindow.close();
        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
      });
    }
  }

  function center_map(map) {
    var bounds = new google.maps.LatLngBounds();
    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );
      bounds.extend(latlng);
    });
    if (map.markers.length == 1) {
      map.setCenter(bounds.getCenter());
    } else {
      map.fitBounds(bounds);
    }
  }

  $('.acf-map').each(function () {
    render_map($(this));
  });

  // Anchor tabs

  const tabContent = function (el) {
    let contentDiv = el
      .closest('.cell')
      .siblings('.cell')
      .find('.entry__content');
    let headers = contentDiv.find($('h1,h2,h3,h4,h5,h6,strong'));
    headers.each(function () {
      let idText = $(this)
        .text()
        .toLowerCase()
        .replace(/[^a-z0-9\s]/gi, '')
        .replace(/[_\s]/g, '-');
      $(this).attr('id', idText);
    });
    // console.log($('*:contains(' + el.find('a').text() + ')'));
    // let idText = .text().toLowerCase().replace(/ /g, '_');
    $(el.find('a')).each(function () {
      let idText = $(this)
        .text()
        .toLowerCase()
        .replace(/[^a-z0-9\s]/gi, '')
        .replace(/[_\s]/g, '-');
      $(this).attr('href', '#' + idText);
    });
  };

  tabContent($('.anchors-wrapper'));

  // $('.anchors-tabs__title').on('mouseenter', function () {
  //   let elArray = $('.anchors-tabs__title');
  //   elArray.removeClass('active-tab');
  //   $(this).addClass('active-tab');
  // });
  $('.anchors-tabs__link').on('click', function () {
    let anchor = $(this).attr('href');
    $('html, body')
      .stop()
      .animate(
        {
          scrollTop: $(anchor).offset().top - 70,
        },
        1500
      );
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  jarallaxElement();
  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.5,
  });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  //$('.anchors-wrapper__inner').hide();
  $('.anchors-wrapper--head').each(function () {
    $(this).click(function () {
      $(this).toggleClass('hide-list-anchors');
      $(this).next('.tab-content').slideToggle();
    });
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
  $('.news-slider').slick({
    cssEase: 'ease',
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    rows: 0,
    responsive: [
      {
        breakpoint: 860,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('.available-list').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 685,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});
$('.gform_wrapper .gfield_required').html('*');

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */

$(window).on('scroll', function () {
  // jQuery code goes here
  // if ($(this).scrollTop() > 1 && window.innerWidth > 1024) {
  //   $('.header').addClass('sticky');
  // } else {
  //   $('.header').removeClass('sticky');
  // }
});

let element = $('.lottie-anim');
if (element.length) {
  element.each(function () {
    let path = $(this).data('source');
    lottie.loadAnimation({
      container: this,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: path,
    });
  });
}

$(window).on('load changed.zf.mediaquery', function () {
  $('.home-slide__inner').each(function () {
    let imgSrc = null;
    // eslint-disable-next-line no-undef
    if (Foundation.MediaQuery.is('small down')) {
      imgSrc = $(this).data('mobile-background');
    }
    jarallax(this, 'destroy');
    jarallax(this, {
      speed: 0.5,
      imgSrc,
    });
  });
});
